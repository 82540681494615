import { Suspense, useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

import {
  useAmortizationTime,
  useFeedInSync,
  useFinancialCalculations,
  useProjectCosts,
  useSynchronizeInverterPreference,
} from "src/hooks";

import { convertUserFromDB } from "src/db-converters";
import { openCorrPopupBasedOnPartnerStatus } from "src/helpers/partner-cities/partnerCityHelper";
import { useLoginAPI } from "src/hooks/apis";
import { useFetchRefCode } from "src/hooks/useFetchRefCode";
import { useGetConstants } from "src/hooks/useGetConstants";
import { useYearlyConsumption } from "src/hooks/useYearlyConsumption";
import { Loader } from "src/shared/components";
import { colors } from "src/utils/colors";

import * as S from "./ApplicationPage.styles";
import {
  ApplicationHeader,
  ApplicationStatus,
  ApplicationStep1,
  ApplicationStepper,
  ApplicationStep3,
  ApplicationStep4,
  ApplicationStep2,
} from "./components";
import FooterButtons from "./components/FooterButtons/FooterButtons";
import {
  useAssignDefaultProducts,
  useLoadInitialProducts,
  useModelUpdate,
  useQuoteChange,
  useSolarPanelProduction,
  useWelcomeAddressPopup,
} from "./hooks";
import { useHubspotUpdate } from "./hooks/useHubspotUpdate";
import { useUpdateFilteredProducts } from "./hooks/useUpdateFilteredProducts";

import { PopupActions, UserActions } from "src/redux/actionCreators";
import { selectors as AppSelectors } from "src/redux/application";
import { POPUP_KEYS } from "src/redux/popups";
import { getQuote } from "src/redux/project/selectors";
import { PopupSelectors } from "src/redux/selectors";
import store from "src/redux/store";

// import { jwtDecode } from "jwt-decode";

import { UserAuthStatus } from "src/redux/user";
import { openPopup } from "src/redux/popups/action/action.creators";

export const ApplicationPage: React.FC = () => {
  const currentStep = useSelector(AppSelectors.getCurrentStep);
  const { quoteFetching: isQuoteFetching, isProjectLoading } = useSelector(AppSelectors.getState);

  const [query] = useSearchParams();
  useEffect(() => {
    const NewProjectSlug = query.get("isNewProject");
    console.log(NewProjectSlug, "ok");
    if (NewProjectSlug) {
      dispatch(openPopup(POPUP_KEYS.welcome_address));
      dispatch(openPopup(POPUP_KEYS.saved_project_user_info));
    }
  }, [query]);

  // Direct login from URL
  const dispatch = useDispatch();
  const location = useLocation();
  const { error, fetchData, loading, response } = useLoginAPI();

  useEffect(() => {
    try {
      if (!response) return;

      const {
        data: { user, token },
      } = response.data;

      localStorage.setItem("token", token);

      const userState = convertUserFromDB(user);
      dispatch(UserActions.setUser(userState));
      const authPopupProps = useSelector(PopupSelectors.getAuthPopup);
      const quote = useSelector(getQuote);
      dispatch(UserActions.setUserAuthStatus(UserAuthStatus.loggedIn));
      if (authPopupProps.data.afterSuccess) {
        authPopupProps.data.afterSuccess();
      }

      if (quote.mapboxAddress.complete !== "Ringstraße 7, 79252 Stegen, Germany") {
        openCorrPopupBasedOnPartnerStatus();
      }

      dispatch(PopupActions.closePopup(POPUP_KEYS.auth));
    } catch (error) {
      console.error("Auto login failed!!", error);
    }
  }, [response]);

  // useEffect(() => {
  //   try {
  //     const tokenFromUrl = new URLSearchParams(location.search).get("reference");

  //     if (tokenFromUrl) {
  //       // Decode the JWT token
  //       const decodedToken: any = jwtDecode(tokenFromUrl);

  //       // Access data inside the JWT
  //       const { email, password } = decodedToken;

  //       // Use the data as needed
  //       void fetchData({
  //         ...BackendEndpoints.login,
  //         data: {
  //           email,
  //           password,
  //         },
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Auto login failed!!", error);
  //   }
  // }, []);

  useWelcomeAddressPopup();
  useLoadInitialProducts();
  useSolarPanelProduction();
  useYearlyConsumption();
  useProjectCosts();
  useFinancialCalculations();
  useAmortizationTime();
  useModelUpdate();
  useQuoteChange();
  useUpdateFilteredProducts();
  useSynchronizeInverterPreference();
  useFeedInSync();
  useHubspotUpdate();
  useGetConstants();
  useFetchRefCode();

  return (
    <S.ApplicationBodyContainer id="application-body-container">
      {currentStep === 0 && <ApplicationStatus />}
      <ApplicationStepper />
      <S.HeaderWrapper>
        <ApplicationHeader />
      </S.HeaderWrapper>
      <S.StepsWrapper>
        <Suspense fallback={<div>Loading...</div>}>
          {currentStep === 0 && <ApplicationStep1 />}
          {currentStep === 1 && <ApplicationStep2 />}
          {currentStep === 2 && <ApplicationStep3 />}
          {currentStep === 3 && <ApplicationStep4 />}
        </Suspense>
      </S.StepsWrapper>
      <FooterButtons />
      <Loader loading={isQuoteFetching || isProjectLoading} color={colors.orangeSolid} />
    </S.ApplicationBodyContainer>
  );
};
