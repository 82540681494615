import { cloneDeep } from "lodash";
import { number } from "prop-types";

import { WelcomeNoAddressProps } from "src/popups";
import { AUTH_MODES, AuthPopupProps, PartnerCityPopupProps } from "src/popups/auth/types";
import { CarSelectorProps } from "src/popups/carselector/CarSelector";

import { actionHandlers, actionNames, POPUP_KEYS, PopupState } from "src/redux/popups";

const defaultAuthPopupProps: AuthPopupProps = {
  authMode: AUTH_MODES.signup,
};

export interface InstallerPopupData {
  noOfTimesShown: number;
}
export const defaultPartnerCityPopupProps: PartnerCityPopupProps = {
  title: "Bisher wurde für dein Gebäude noch keine Planung angelegt, lass uns gerne loslegen!",
  description:
    "Mit unserem vielfältigen Angebot helfen wir dir dabei, deine individuelle Energielösung zu finden. Wir bieten dir eine unabhängige Beratung von Solarenergie über Wärmepumpe zu E-Mobilität.Wir sind dein Ansprechpartner um die beste Entscheidung zu treffen.",
  question: "Wie möchtest du starten?",
};

const defaultCarSelectorProps: CarSelectorProps = {
  isAdd: true,
  addSettings: {
    isMultipleAdd: true,
  },
};

const INITIAL_STATE: PopupState = {
  DATA_POLICY: {
    open: false,
    data: {
      accept: false
    }
  },
  SAVED_PROJECT_USER_INFO: {
    open: false,
    data: {} as any,
  },
  PDF_TEMPLATE_SELECTION: {
    open: false,
    data: {} as any,
  },
  INSTALLER_CONFIRMATION: {
    open: false,
    data: {
      noOfTimesShown: 0,
    },
  },
  AUTH_POPUP: {
    open: false,
    data: cloneDeep(defaultAuthPopupProps),
  },
  WEBINAR_POPUP: {
    open: false,
    data: {
      title: "",
      heading: "",
      button_text: "",
      extraContent: "",
    },
  },
  INSTALLER_OFFER: {
    open: false,
    data: {},
  },
  WELCOME_WEBINAR: {
    open: false,
    data: {
      button_text: "Select option",
    },
  },
  WELCOME_ADDRESS: {
    open: false,
    data: {},
  },
  WEBINAR_PROJECT_DETAILS: {
    open: false,
    data: {} as any,
  },
  CONSUMPTION_PROFILE_DIALOG: {
    open: false,
    data: {} as any,
  },
  SELECT_BUILDING_TYPE: {
    open: false,
    data: {} as any,
  },
  PRODUCT_POPUP: {
    open: false,
    data: {} as any,
  },
  CAR_SELECTOR: {
    open: false,
    data: defaultCarSelectorProps,
  },
  CONSUMPTION_CHARTS: {
    open: false,
    data: {} as any,
  },
  HUBSPOT_MEETING: {
    open: false,
    data: {} as any,
  },
  PARTNER_CITY_CONSULTINNG: {
    open: false,
    data: cloneDeep(defaultPartnerCityPopupProps),
  },
  PRODUCT_DETAILS_POPUP: {
    open: false,
    data: {} as any,
  },
  PRODUCT_INFO: {
    open: false,
    data: {} as any,
  },
  PAY_WALL: {
    open: false,
    data: {} as any,
  },
  PAYMENT_WITH_CARD: {
    open: false,
    data: {} as any,
  },
  SOLAR_LAYOUT: {
    open: false,
    data: {} as any,
  },
  REQUEST_PV_MODEL: {
    open: false,
    data: {} as any,
  },
  OFFER_FEEDBACK: {
    open: false,
    data: {} as any,
  },
  MULTI_TENANT: {
    open: false,
    data: {} as any,
  },
  SUBSIDY: {
    open: false,
    data: {} as any,
  },
  THREEDMODEL: {
    open: false,
    data: {} as any,
  },
  PDF_PREVIEW: {
    open: false,
    data: {} as any,
  },
};

export type WebinarPopupProps = typeof INITIAL_STATE.WEBINAR_POPUP.data;

const popupReducer = (state = INITIAL_STATE, action: any): typeof INITIAL_STATE => {
  switch (action.type) {
    case actionNames.OPEN_POPUP:
    case actionNames.CLOSE_POPUP:
      return actionHandlers.setPopup(state, action);
    case actionNames.UPDATE_DATA:
      return {
        ...state,
        [action.payload.popupKey]: {
          ...state[action.payload.popupKey as keyof PopupState],
          data: action.payload.data,
        },
      };
    case "RESET_REDUX":
      return cloneDeep(INITIAL_STATE);
    default:
      return state;
  }
};

export default popupReducer;
