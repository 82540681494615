import { cloneDeep } from "lodash";

import { ProductFilters } from "src/hooks";

import {
  CarRequestDB,
  CarResponseDB,
  ComponentRequestDB,
  ComponentResponseDB,
  ElectricMeterRequestDB,
  FinancialDetailsRequestDB,
  FinancialDetailsResponseDB,
  HeatpumpRequestDB,
  HeatpumpResponseDB,
  HouseBottomRequestDB,
  HouseTopRequestDB,
  HouseholdDetailsRequestDB,
  HouseholdDetailsResponseDB,
  ProjectImagesResponseDB,
  ProjectResponseDB,
  ProjectSaveRequestDB,
  ThreeDModelsRequestDB,
} from "src/db-types/project";

import { convertQuoteFromDB } from "./quote";

import { defaultFilters } from "src/redux/application/reducer/application.reducer";
import {
  BaseHouseImages,
  CarComponent,
  ElectricMeterImages,
  HeatpumpComponent,
  HouseTopImages,
  HouseholdEnergyParams,
  ProjectImages,
  ProjectState,
  QuoteProfileName,
} from "src/redux/project";
import { defaultComponent } from "src/redux/project/default-values";
import { defaultProjectState, initialState } from "src/redux/project/initialState";
import { FilterableBaseComponent } from "src/redux/project/types/components";
import { FeedInReason, ProjectFinancialDetails } from "src/redux/project/types/financial-details";
import { ThreeDModels } from "src/redux/project/types/house-images";
import { PaymentStatus } from "src/redux/project/types/payment";

export const convertFinancialDetailsToDB = (
  details: ProjectFinancialDetails,
): FinancialDetailsRequestDB => {
  const {
    amortizationTime,
    estimatedYearlyPriceIncrease,
    expectedReturnTime,
    feedInSavings,
    feedInTariff,
    increaseRateByBank,
    isFinancing,
    loan,
    pricePerKwh,
    selectedFeedInReason,
    buy,
  } = details;
  return {
    amortizationTime,
    buy,
    electricityCostBefore: 0, // TODO why???
    estimatedYearlyPriceIncrease,
    expectedReturnTime,
    feedInSavings,
    feedInTariff,
    increaseRateByBank,
    isFinancing,
    loan,
    pricePerKwh,
    selectedFeedInReason,
  };
};

export const convertSolarPanelToDB = (solarPanel: FilterableBaseComponent): ComponentRequestDB => {
  const { extendedWarranty, item, quantity, isExcludeCost, preferences } = solarPanel;
  return {
    extendedWarranty,
    isExcludeCost,
    item: item?._id ?? null,
    quantity,
    preferences,
  };
};

export const convertInverterToDB = (inverter: FilterableBaseComponent): ComponentRequestDB => {
  const { extendedWarranty, item, quantity, isExcludeCost, preferences } = inverter;
  return {
    extendedWarranty,
    isExcludeCost,
    item: item?._id ?? null,
    quantity,
    preferences,
  };
};

export const convertEMSToDB = (EMS: FilterableBaseComponent): ComponentRequestDB => {
  const { extendedWarranty, item, quantity, isExcludeCost, preferences } = EMS;
  return {
    extendedWarranty,
    isExcludeCost,
    item: item?._id ?? null,
    quantity,
    preferences,
  };
};

export const convertBatteryToDB = (battery: FilterableBaseComponent): ComponentRequestDB => {
  const { extendedWarranty, item, quantity, isExcludeCost, preferences } = battery;
  return {
    extendedWarranty,
    isExcludeCost,
    item: item?._id ?? null,
    quantity,
    preferences,
  };
};

export const convertWallboxToDB = (wallbox: FilterableBaseComponent): ComponentRequestDB => {
  const { extendedWarranty, item, quantity, isExcludeCost, preferences } = wallbox;
  return {
    extendedWarranty,
    isExcludeCost,
    item: item?._id ?? null,
    quantity,
    preferences,
  };
};

export const convertHeatpumpToDB = (heatpump: HeatpumpComponent): HeatpumpRequestDB => {
  const { extendedWarranty, item, quantity, configuration, isExcludeCost, preferences } = heatpump;
  const {
    blockingTimes,
    buildingStandard,
    consumptionHeatpump,
    electricityCostHeatpump,
    energyDemand,
    heatDistribution,
    heatLoadBuilding,
    heatingCapacity,
    heatpumpDimension,
    oilGasCost,
    solarPowerForHeatpump,
    totalAreaToBeHeated,
    futureOilGasCostIncrease,
  } = configuration;
  return {
    extendedWarranty,
    isExcludeCost,
    item: item?._id ?? null,
    quantity,
    configuration: {
      blockingTimes,
      buildingStandard,
      consumptionHeatpump,
      electricityCostHeatpump,
      energyDemand,
      heatDistribution,
      heatingCapacity,
      heatLoadBuilding,
      oilGasCost,
      solarPowerForHeatpump,
      totalAreaToBeHeated,
      futureOilGasCostIncrease,
      heatpump_dimension: heatpumpDimension,
    },
    preferences,
  };
};

export const convertCarToDB = (car: CarComponent): CarRequestDB => {
  const { configuration, extendedWarranty, id, isLeasing, item, name, quantity } = car;

  const {
    buildingStandard,
    consumptionPer100Km,
    electricityCostPer100Km,
    evKmsTravelled,
    evYearlyConsumption,
    gasPricePer100Km,
    solarPowerForVehicle,
  } = configuration;

  return {
    buildingStandard,
    consumptionPer100Km,
    electricityCostPer100Km,
    evKmsTravelled,
    evYearlyConsumption,
    gasPricePer100Km,
    id,
    isLeasing,
    quantity,
    solarPowerForVehicle,
    item: item?._id ?? null,
  };
};

export const convertHousholdToDB = (
  household: HouseholdEnergyParams,
): HouseholdDetailsRequestDB => {
  const {
    buildingType,
    calculatedConsumption,
    consumption,
    consumptionProfile,
    kwaPerEmployee,
    people,
    selfSupply,
    systemConsept,
    households,
    expectedTenants,
    electricityPrice,
    expectedSolar,
    expectedHardware,
    expectedSoftware,
    measuringPoint,
    multiTenantLicenceFees,
    generalElectricity,
    operationConcept,
    operationModal,
  } = household;

  return {
    buildingType,
    consumptionProfile,
    enteredConsumption: consumption || calculatedConsumption,
    kwaPerEmployee,
    people,
    selfSupply,
    systemConsept,
    households,
    expectedTenants,
    electricityPrice,
    expectedSolar,
    multiTenantLicenceFees,
    expectedHardware,
    expectedSoftware,
    measuringPoint,
    generalElectricity,
    operationConcept,
    operationModal,
  };
};

export const convertHouseTopImageToDB = (houseTopImage: HouseTopImages): HouseTopRequestDB => {
  const { buildingYearId, description, imageURLs } = houseTopImage;
  return {
    buildingYearId,
    description,
    imageURLs,
  };
};

export const convertHouseBottomToDB = (houseBottom: BaseHouseImages): HouseBottomRequestDB => {
  const { description, imageURLs } = houseBottom;
  return {
    description,
    imageURLs,
  };
};

export const convertDroneDataToDB = (ThreeDModels: ThreeDModels): ThreeDModelsRequestDB => {
  const {
    description,
    imageURLs,
    planYourSelf,
    pvSolModifyModel,
    threeDpvModelSunnyDesign,
    threeDpvModelSolarEdgeDesign,
  } = ThreeDModels;
  return {
    description,
    imageURLs,
    planYourSelf,
    pvSolModifyModel,
    threeDpvModelSunnyDesign,
    threeDpvModelSolarEdgeDesign,
  };
};

export const convertElectricMeterToDB = (
  electricMeter: ElectricMeterImages,
): ElectricMeterRequestDB => {
  const { description, imageURLs, meterTypeId } = electricMeter;
  return {
    meterTypeId,
    description,
    imageURLs,
  };
};

const defaultBakeModeldata = {
  id: "",
  artifacts: null,
};

const defaultBakeModelProgressdata = {
  percentage: "",
  time: null,
  elapsed: 0,
};

export const convertProjectToSaveRequest = (project: ProjectState): ProjectSaveRequestDB => {
  return {
    quoteID: project.quoteId,
    profile: project.profile,
    vendor: project.vendor, // todo pure function
    payment: project.payment, // todo pure function
    bakeModel: project?.bakeModel || defaultBakeModeldata, // todo pure funciton
    trackProgress: project?.trackProgress || defaultBakeModelProgressdata,
    projectPdf: project.projectPdf || "",
    financialDetails: convertFinancialDetailsToDB(project.financialDetails),
    accessCount: project?.accessCount,
    energyProductionParams: project.energyProductionParams,
    household: convertHousholdToDB(project.household),
    images: {
      electricMeter: convertElectricMeterToDB(project.images.electricMeter),
      houseBottom: convertHouseBottomToDB(project.images.houseBottom),
      houseTop: convertHouseTopImageToDB(project.images.houseTop),
      ThreeDModels: convertDroneDataToDB(project.images.ThreeDModels),
    },
    components: {
      battery: convertBatteryToDB(project.components.battery),
      inverter: convertInverterToDB(project.components.inverter),
      solar_panel: convertSolarPanelToDB(project.components.solarPanel),
      heatpump: convertHeatpumpToDB(project.components.heatpump),
      waterHeating: project.components.waterHeating,
      wallBox: convertWallboxToDB(project.components.wallbox),
      cars: project.components.cars.map((c) => convertCarToDB(c)),
      EMS: convertInverterToDB(project.components.EMS),
    },
    threeDObjDetails: {
      createdLines: project?.threeDObjDetails?.createdLines,
      createdPoints: project?.threeDObjDetails?.createdPoints,
      areas: project?.threeDObjDetails?.areas,
      perimeters: project?.threeDObjDetails?.perimeters,
      createdLineLoops: project?.threeDObjDetails?.createdLineLoops,
      panelsQuantity: project?.threeDObjDetails?.panelsQuantity,
      panelPlacement: project?.threeDObjDetails?.panelPlacement,
    },
    polygonViseDetails: project.polygonViseDetails,
    maxWidth3DViewer: project?.maxWidth3DViewer,
    maxWidth3DViewerWithImages: project?.maxWidth3DViewerWithImages,
    installer: project?.installer,
    iid: project?.iid,
    userInfo: project?.userInfo,
    subsidy: project?.subsidy,
    manualSubsidyDetails: project?.manualSubsidyDetails,
  };
};

export const convertDBPreferences = (pref: Record<string, any>): ProductFilters => {
  pref = (pref as ProductFilters) ?? {};
  return {
    ...defaultFilters,
    ...pref,
  };
};

export const convertDBBattery = (data: ComponentResponseDB): FilterableBaseComponent => {
  const { extendedWarranty, isExcludeCost, quantity, item, preferences } = data;
  return {
    extendedWarranty,
    item: item ?? null,
    quantity,
    isExcludeCost,
    preferences: convertDBPreferences(preferences),
  };
};

export const convertDBEMS = (data: ComponentResponseDB): FilterableBaseComponent => {
  console.log(data, "plpo")
  const { extendedWarranty, isExcludeCost, quantity, item, preferences } = data;
  return {
    extendedWarranty,
    item: item ?? null,
    quantity,
    isExcludeCost,
    preferences: convertDBPreferences(preferences),
  };
};

export const convertDBSolarPanel = (data: ComponentResponseDB): FilterableBaseComponent => {
  const { extendedWarranty, isExcludeCost, quantity, item, preferences } = data;
  return {
    extendedWarranty,
    item: item ?? null,
    quantity,
    isExcludeCost,
    preferences: convertDBPreferences(preferences),
  };
};

export const convertDBAdditionalSoftware = (data: ComponentResponseDB): FilterableBaseComponent => {
  if (data) {
    const { extendedWarranty, isExcludeCost, quantity, item, preferences } = data;
    return {
      extendedWarranty,
      item: item ?? null,
      quantity,
      isExcludeCost,
      preferences: convertDBPreferences(preferences),
    };
  } else {
    return defaultComponent;
  }
};

export const convertDBAdditionalHardware = (data: ComponentResponseDB): FilterableBaseComponent => {
  if (data) {
    const { extendedWarranty, isExcludeCost, quantity, item, preferences } = data;
    return {
      extendedWarranty,
      item: item ?? null,
      quantity,
      isExcludeCost,
      preferences: convertDBPreferences(preferences),
    };
  } else {
    return defaultComponent;
  }
};

export const convertDBEMSToState = (data: ComponentResponseDB): FilterableBaseComponent => {
  if (data) {
    const { extendedWarranty, isExcludeCost, quantity, item, preferences } = data;
    return {
      extendedWarranty,
      item: item ?? null,
      quantity,
      isExcludeCost,
      preferences: convertDBPreferences(preferences),
    };
  } else {
    return defaultComponent;
  }
}

export const convertDBInverter = (data: ComponentResponseDB): FilterableBaseComponent => {
  const { extendedWarranty, isExcludeCost, quantity, item, preferences } = data;
  return {
    extendedWarranty,
    item: item ?? null,
    quantity,
    isExcludeCost,
    preferences: convertDBPreferences(preferences),
  };
};

export const convertDBWallbox = (data: ComponentResponseDB): FilterableBaseComponent => {
  const { extendedWarranty, isExcludeCost, quantity, item, preferences } = data;
  return {
    extendedWarranty,
    item: item ?? null,
    quantity,
    isExcludeCost,
    preferences: convertDBPreferences(preferences),
  };
};

export const convertDBCar = (data: CarResponseDB): CarComponent => {
  const {
    buildingStandard,
    consumptionPer100Km,
    electricityCostPer100Km,
    evKmsTravelled,
    evYearlyConsumption,
    gasPricePer100Km,
    id,
    isLeasing,
    quantity,
    solarPowerForVehicle,
    item,
  } = data;

  return {
    extendedWarranty: false, // todo
    item: item ?? null,
    quantity,
    id,
    isLeasing,
    name: id,
    configuration: {
      buildingStandard,
      consumptionPer100Km,
      electricityCostPer100Km,
      evKmsTravelled,
      evYearlyConsumption,
      gasPricePer100Km,
      solarPowerForVehicle,
    },
    isExcludeCost: false,
  };
};

export const convertDBHeatpump = (data: HeatpumpResponseDB): HeatpumpComponent => {
  const { configuration, extendedWarranty, isExcludeCost, quantity, item, preferences } = data;

  const {
    blockingTimes,
    buildingStandard,
    consumptionHeatpump,
    electricityCostHeatpump,
    energyDemand,
    heatDistribution,
    heatLoadBuilding,
    heatingCapacity,
    heatpump_dimension: heatpumpDimension,
    oilGasCost,
    solarPowerForHeatpump,
    totalAreaToBeHeated,
    futureOilGasCostIncrease,
    jaz,
  } = configuration;

  return {
    extendedWarranty,
    item: item ?? null,
    quantity,
    configuration: {
      blockingTimes,
      buildingStandard,
      consumptionHeatpump,
      electricityCostHeatpump,
      energyDemand,
      heatDistribution,
      heatingCapacity,
      heatLoadBuilding,
      heatpumpDimension,
      oilGasCost,
      solarPowerForHeatpump,
      totalAreaToBeHeated,
      futureOilGasCostIncrease,
      jaz: jaz ?? 3.5,
    },
    isExcludeCost,
    preferences: convertDBPreferences(preferences),
  };
};

export const convertDBFinancialDetails = (
  data: FinancialDetailsResponseDB,
): ProjectFinancialDetails => {
  const {
    _id,
    amortizationTime,
    buy,
    electricityCostBefore,
    estimatedYearlyPriceIncrease,
    expectedReturnTime,
    feedInSavings,
    feedInTariff,
    increaseRateByBank,
    isFinancing,
    loan,
    pricePerKwh,
    selectedFeedInReason,
  } = data;

  return {
    ...cloneDeep(defaultProjectState.financialDetails),
    pricePerKwh,
    estimatedYearlyPriceIncrease,
    increaseRateByBank,
    feedInTariff,
    selectedFeedInReason: selectedFeedInReason as FeedInReason,
    isFinancing,
    expectedReturnTime,
    amortizationTime,
    feedInSavings,
  };
};

export const convertDBHousehold = (data: HouseholdDetailsResponseDB): HouseholdEnergyParams => {
  const {
    _id,
    buildingType = initialState.household.buildingType,
    consumptionProfile = initialState.household.consumptionProfile,
    enteredConsumption = initialState.household.consumption,
    kwaPerEmployee = initialState.household.kwaPerEmployee,
    people = initialState.household.people,
    systemConsept = initialState.household.systemConsept,
    households = initialState.household.households,
    expectedTenants = initialState.household.expectedTenants,
    electricityPrice = initialState.household.electricityPrice,
    expectedSolar = initialState.household.expectedSolar,
    expectedHardware = initialState.household.expectedHardware,
    expectedSoftware = initialState.household.expectedSoftware,
    measuringPoint = initialState.household.measuringPoint,
    generalElectricity = initialState.household.generalElectricity,
    multiTenantLicenceFees = initialState.household.multiTenantLicenceFees,
    operationConcept = initialState.household.operationConcept,
    operationModal = initialState.household.operationModal,
    selfSupply,
  } = data;

  return {
    buildingType,
    calculatedConsumption: 0,
    consumption: enteredConsumption,
    consumptionProfile,
    kwaPerEmployee,
    people,
    systemConsept,
    households,
    expectedTenants,
    multiTenantLicenceFees,
    electricityPrice,
    expectedSolar,
    expectedHardware,
    expectedSoftware,
    measuringPoint,
    generalElectricity,
    selfSupply,
    operationConcept,
    operationModal,
  };
};

export const converProjectImagesDBToState = (
  dbProjectImages: ProjectImagesResponseDB,
): ProjectImages => {
  return {
    electricMeter: {
      imageURLs: dbProjectImages.electricMeter.imageURLs ?? [],
      description:
        dbProjectImages.electricMeter.description ?? initialState.images.electricMeter.description,
      meterTypeId:
        dbProjectImages.electricMeter.meterTypeId ?? initialState.images.electricMeter.meterTypeId,
    },
    houseBottom: {
      imageURLs: dbProjectImages.houseBottom.imageURLs ?? [],
      description:
        dbProjectImages.houseBottom.description ?? initialState.images.houseBottom.description,
    },
    ThreeDModels: {
      imageURLs: dbProjectImages?.ThreeDModels?.imageURLs ?? [],
      description:
        dbProjectImages?.ThreeDModels?.description ?? initialState.images.ThreeDModels.description,
      planYourSelf:
        dbProjectImages?.ThreeDModels?.planYourSelf ??
        initialState.images.ThreeDModels.planYourSelf,
      pvSolModifyModel:
        dbProjectImages?.ThreeDModels?.pvSolModifyModel ??
        initialState?.images?.ThreeDModels?.pvSolModifyModel,
      threeDpvModelSunnyDesign:
        dbProjectImages?.ThreeDModels?.threeDpvModelSunnyDesign ??
        initialState.images.ThreeDModels.threeDpvModelSunnyDesign,
      threeDpvModelSolarEdgeDesign:
        dbProjectImages?.ThreeDModels?.threeDpvModelSolarEdgeDesign ??
        initialState.images.ThreeDModels.threeDpvModelSolarEdgeDesign,
      selectedFiles: [],
    },
    houseTop: {
      imageURLs: dbProjectImages.houseTop.imageURLs ?? [],
      buildingYearId:
        dbProjectImages.houseTop.buildingYearId ?? initialState.images.houseTop.buildingYearId,
      description: dbProjectImages.houseTop.description ?? initialState.images.houseTop.description,
    },
  };
};

export const convertProjectDBToState = (dbProject: ProjectResponseDB): Partial<ProjectState> => {
  return {
    id: dbProject._id,
    profile: dbProject.profile as QuoteProfileName,
    uid: dbProject.uid,
    version: dbProject.version,
    type: "b2c", // TODO
    bakeModel: dbProject?.bakeModel || defaultBakeModeldata, // todo pure funciton,
    trackProgress: dbProject?.trackProgress || defaultBakeModelProgressdata,
    accessCount: dbProject?.accessCount,
    components: {
      battery: convertDBBattery(dbProject.components.battery),
      cars: dbProject.components.cars.map(convertDBCar),
      heatpump: convertDBHeatpump(dbProject.components.heatpump),
      inverter: convertDBInverter(dbProject.components.inverter),
      solarPanel: convertDBSolarPanel(dbProject.components.solar_panel),
      waterHeating: dbProject.components.waterHeating,
      wallbox: convertDBWallbox(dbProject.components.wallBox),
      additionalSoftware: convertDBAdditionalSoftware(dbProject.components.additionalSoftware),
      additionalHardware: convertDBAdditionalHardware(dbProject.components.additionalHardware),
      EMS: convertDBEMS(dbProject?.components?.EMS || defaultComponent),
    },
    deal: dbProject.deal,
    energyProductionParams: dbProject.energyProductionParams,
    financialDetails: convertDBFinancialDetails(dbProject.financialDetails),
    household: convertDBHousehold(dbProject.household),
    images: converProjectImagesDBToState(dbProject.images),
    payment: {
      ...dbProject.payment,
      status: dbProject.payment.status as PaymentStatus,
    },
    quoteId: dbProject?.quoteID?._id,

    bakeModelAdminGenerated: dbProject.quoteID.bakeModel,

    shortId: dbProject.shortId,
    quote: convertQuoteFromDB(dbProject.quoteID),
    selectedInstallers: dbProject.selectedInstallers,
    installerDetails: dbProject.installerDetails,
    threeDObjDetails: dbProject.threeDObjDetails,
    polygonViseDetails: dbProject.polygonViseDetails,
    maxWidth3DViewer: dbProject.maxWidth3DViewer,
    maxWidth3DViewerWithImages: dbProject.maxWidth3DViewerWithImages,
    userInfo: dbProject?.userInfo,
    userDetails: dbProject?.userDetails,
    subsidy: dbProject?.subsidy,
    manualSubsidyDetails: dbProject?.manualSubsidyDetails
  };
};
