import { WelcomeNoAddressProps } from "src/popups";
import { AuthPopupProps } from "src/popups/auth/types";
import { CarSelectorProps } from "src/popups/carselector/CarSelector";
import { ProductDetailsPopupProps } from "src/popups/ProductDetailsPopup/ProductDetailsPopup";
import { ProductInfoProps } from "src/popups/ProductInfo/ProductInfoPopup";
import { ProductPopupProps } from "src/popups/ProductPopup/ProductPopup";

import { POPUP_KEYS } from "./popup.keys";
import { InstallerPopupData, WebinarPopupProps } from "./reducer/reducer";

import { RootState } from "src/redux/store";

export const getAuthPopup = (state: RootState): { open: boolean; data: AuthPopupProps } =>
  state.popup.AUTH_POPUP;

export const getWelcomeAddressPopup = (
  state: RootState,
): { open: boolean; data: WelcomeNoAddressProps } => state.popup.WELCOME_ADDRESS;

export const getProductPopup = (state: RootState): { open: boolean; data: ProductPopupProps } =>
  state.popup.PRODUCT_POPUP;

export const getWebinarPopup = (state: RootState): { open: boolean; data: WebinarPopupProps } =>
  state.popup.WEBINAR_POPUP;

export const getPopup = (state: RootState): Record<POPUP_KEYS, { open: boolean; data: any }> =>
  state.popup;

export const getInstallerRequestedPopup = (
  state: RootState,
): { open: boolean; data: InstallerPopupData } => state.popup.INSTALLER_CONFIRMATION;

export const getDataPolicyPopupAccept = (state: RootState): { open: boolean; accept: boolean } => ({
  accept: state.popup.DATA_POLICY.data.accept,
  open: state.popup.DATA_POLICY.open,
});

export const getProductDetailsPopup = (
  state: RootState,
): { open: boolean; data: ProductDetailsPopupProps } => state.popup.PRODUCT_DETAILS_POPUP;

export const getProductInfoPopup = (state: RootState): { open: boolean; data: ProductInfoProps } =>
  state.popup.PRODUCT_INFO;

export const getCarSelectorPopup = (state: RootState): { open: boolean; data: CarSelectorProps } =>
  state.popup.CAR_SELECTOR;

export const getSolarLayoutPopup = (state: RootState): { open: boolean; data: CarSelectorProps } =>
  state.popup.SOLAR_LAYOUT;

export const getSavedprojectUserInfoPopup = (state: RootState): { open: boolean; data: any } =>
  state.popup.SAVED_PROJECT_USER_INFO;

export const getPdfPreviewPopup = (state: RootState): { open: boolean; data: any } =>
  state.popup.PDF_PREVIEW;

export const getPdfTemplateSelectionPopup = (state: RootState): { open: boolean; data: any } =>
  state.popup.PDF_TEMPLATE_SELECTION;