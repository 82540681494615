import styled from "styled-components/macro";

export const styledNewProject = styled.button`
  width: 30px;
  cursor: pointer;
  position: relative;
  height: 30px;
  transition: width 0.1s ease-in-out;

  img {
    visibility: visible;
    z-index: 1;
    height: 30px;
    width: 30px;
  }

  span {
    visibility: hidden;
    z-index: 2;
    align-self: center;
  }

  &:hover span {
    visibility: visible;
    padding-right: 8px;
    align-self: center;
  }
  &:hover img{
    margin-right: 8px;
  }
  &:hover{
    width: 130px
  }`

